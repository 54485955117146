<script>
import ThemeSwitcher from '../ThemeSwitcher';
import HireMeModal from '../HireMeModal.vue';
import feather from 'feather-icons';
import AppHeaderLinks from './AppHeaderLinks.vue';
// import Button from '../reusable/Button.vue';
// import Icon from '../reusable/Icon.vue';

export default {
	components: {
		ThemeSwitcher,
		HireMeModal,
		AppHeaderLinks,
		// Button,
		// Icon,
	},
	data() {
		return {
			isOpen: false,
			theme: '',
			modal: false,
			categories: [
				{
					id: 1,
					value: 'web',
					name: 'Web Application',
				},
				{
					id: 2,
					value: 'mobile',
					name: 'Mobile Application',
				},
				{
					id: 3,
					value: 'ui-ux',
					name: 'UI/UX Design',
				},
				{
					id: 4,
					value: 'branding',
					name: 'Branding & Anim',
				},
			],
			info: { email: 'chesswithryan@gmail.com', phone: '425-623-7900' }
		};
	},

	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	methods: {
		updateTheme(theme) {
			this.theme = theme;
		},
		showModal() {
			if (this.modal) {
				// Stop screen scrolling
				document
					.getElementsByTagName('html')[0]
					.classList.remove('overflow-y-hidden');
				this.modal = false;
			} else {
				document
					.getElementsByTagName('html')[0]
					.classList.add('overflow-y-hidden');
				this.modal = true;
			}
		},
		hideMenu() {
			this.isOpen = false
		}
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<nav id="nav-header" class="sm:container sm:mx-auto flex justify-center h-[48px] ls-rct">
		<!-- <div class="flex justify-between"> -->
		<!-- <div class="col-span-5 xs:col-span-4"> -->
		<a :href="'tel:' + info.phone" class="flex items-center text-base-rct flex md:mr-4">
			<!-- <i data-feather="phone" class="mt-1 w-4 h-4 text-ternary-dark dark:text-ternary-light"></i> -->
			&nbsp;{{ info.phone }}&nbsp;&nbsp;
		</a>
		<!-- </div>
			<div class="col-span-7 xs:col-span-8"> -->
		<a :href="'mailto:' + info.email" class="flex items-center text-base-rct flex">
			<!-- <Icon color="black" type="email" /> -->
			<!-- <i data-feather="mail" class="mt-1 w-4 h-4 text-ternary-dark dark:text-ternary-light"></i> -->

			&nbsp;{{ info.email }}
		</a>
		<!-- </div> -->
		<!-- </div> -->
	</nav>
	<nav id="nav" class="xl:container sm:mx-auto app-menu h-[72px]  px-3">
		<!-- Header start -->
		<div class="z-10 max-w-screen-lg xl:max-w-screen-xl block sm:flex sm:justify-between sm:items-center">
			<!-- Header menu links and small screen hamburger menu -->
			<div class="flex justify-between items-center md:px-4 sm:px-0 h-[72px]">
				<!-- Header logos -->
				<div class="">
					<router-link to="/"><img src="@/assets/images/knight-logo.png" class="h-16 logo-shadow"
							alt="Dark Logo" />
						<!-- <img v-else src="@/assets/images/logo-light.svg" class="w-24" alt="Light Logo" /> -->
					</router-link>
				</div>

				<!-- Theme switcher small screen -->
				<theme-switcher v-if="0" :theme="theme" @themeChanged="updateTheme"
					class="block sm:hidden bg-ternary-light dark:bg-ternary-dark hover:bg-hover-light dark:hover:bg-hover-dark hover:shadow-sm px-2.5 py-2 rounded-lg" />

				<!-- Small screen hamburger menu -->
				<div class="sm:hidden">
					<button @click="isOpen = !isOpen" type="button" class="focus:outline-none mt-1.5 md:mt-0"
						aria-label="Hamburger Menu">
						<svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_476_7)">
								<rect width="24" height="4" fill="#2F2F2F" />
								<rect y="7" width="24" height="4" fill="#2F2F2F" />
								<rect y="14" width="24" height="4" fill="#2F2F2F" />
							</g>
							<defs>
								<clipPath id="clip0_476_7">
									<rect width="24" height="18" fill="white" />
								</clipPath>
							</defs>
						</svg>
						<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
							class="h-7 w-7 fill-current text-secondary-dark dark:text-ternary-light">
							<path v-if="isOpen" fill-rule="evenodd"
								d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
								clip-rule="evenodd"></path>
							<path v-if="!isOpen" fill-rule="evenodd"
								d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z">
							</path>
						</svg> -->
					</button>
				</div>
			</div>

			<!-- Header links -->
			<AppHeaderLinks :showModal="showModal" :isOpen="isOpen" />

			<!-- Header right section buttons -->
			<div class="hidden sm:flex justify-between items-center flex-col md:flex-row">
				<!-- Hire me button OCULTO -->
				<!-- <div class="hidden md:block">
					<Button
						title="orçamento"
						class="text-md font-general-medium bg-pink-500 hover:bg-pink-600 text-white shadow-sm rounded-md px-5 py-2.5 duration-300"
						@click="showModal()"
						aria-label="Orçamento Botão"
					/>
				</div> -->

				<!-- Theme switcher large screen -->
				<theme-switcher v-if="0" :theme="theme" @themeChanged="updateTheme"
					class="ml-8 bg-primary-light dark:bg-ternary-dark px-3 py-2 shadow-sm rounded-xl cursor-pointer" />
			</div>
		</div>

		<!-- Hire me modal -->
		<HireMeModal :showModal="showModal" :modal="modal" :categories="categories" aria-modal="Hire Me Modal" />
	</nav>
</template>

<style scoped>
#nav a.router-link-exact-active {
	@apply text-pink-700;
	/* @apply dark:text-pink-400; */
	@apply font-medium;
}
</style>
