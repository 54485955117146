<script>
import feather from 'feather-icons';
// import ProjectsFilter from './ProjectsFilter.vue';
// import ProjectSingle from './ProjectSingle.vue';
import projects from '../../data/projects';
import Button from '../../components/reusable/Button.vue';
import Icon from '../../components/reusable/Icon.vue';
import AppInfluential from '../../components/influence/AppInfluential.vue';

export default {
	components: { Button, Icon, /*ProjectSingle, ProjectsFilter,*/ AppInfluential },
	data: () => {
		return {
			projects,
			projectsHeading: 'Portfólio de Projetos',
			selectedCategory: '',
			searchProject: '',
			qualities: [
				"12 years of coaching experience",
				"Spokane City Champion 2022-2023",
				'1820 USCF Rating',
				'2011 Rapid Rating'

			],
			quality_links: [
				'',
				'https://spokanechessclub.org/club-info/city-championship/',
				'https://www.uschess.org/msa/MbrDtlMain.php?13432771',
				'https://www.chess.com/stats/live/rapid/ryantime'
			]
		};
	},
	computed: {
		// Get the filtered projects
		filteredProjects() {
			if (this.selectedCategory) {
				return this.filterProjectsByCategory();
			} else if (this.searchProject) {
				return this.filterProjectsBySearch();
			}
			return this.projects;
		},
	},
	methods: {
		// Filter projects by category
		filterProjectsByCategory() {
			return this.projects.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				console.log(category);
				return category.includes(this.selectedCategory);
			});
		},
		// Filter projects by title search
		filterProjectsBySearch() {
			let project = new RegExp(this.searchProject, 'i');
			return this.projects.filter((el) => el.title.match(project));
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<!-- Projects grid -->
	<section class="lg:mt-8">
		<!-- Projects grid title -->
		<!-- <div class="text-center">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light">
				{{ projectsHeading }}
			</p>
		</div> -->
		<!-- <div class="lg:w-1/2 mx-auto">
			<img class="w-auto" src="@/assets/images/components/HomeComponent.png">
		</div> -->

		<div class="text-left lg:px-4 py-2 lg:py-4 w-full lg:w-2/3 mx-auto shadow">

			<h2 class="page-header-border mx-auto mb-5 text-center">
				Hi, I'm Ryan Ackerman</h2>
			<h1 class="mx-auto mb-5 lg:line-height-6 text-left">
				And I absolutely love playing and teaching chess</h1>
			<ul class="lg:pl-5 mt-2 space-y-1 list-disc list-inside lg:max-w-3xl mx-auto">

				<li class="flex font-general-regular text-left text-secondary-dark dark:text-ternary-light text-3xl font-bold mb-2"
					v-for="(item, index) in qualities" :key="item">
					<Icon color="black" size="small" type="check-outline" />
					<p class="md:mt-1">
						<a v-if="quality_links[index]" :href="quality_links[index]" target="_blank">{{ item }}</a>
						<a v-else href="javascript:void(0);">{{ item }}</a>
					</p>
				</li>
			</ul>
		</div>

		<div class="flex justify-center">
			<router-link to="/book-class"
				class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-pink-600 dark:hover:text-pink-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
				aria-label="Book a Class">
				<Button title="Book a Class" color="primary" size="block" />
			</router-link>
		</div>

		<AppInfluential />

	</section>
</template>

<style scoped></style>
