<script>
import Icon from '../../components/reusable/Icon.vue';
export default {
    components: { Icon },
    props: ['showModal', 'isOpen'],
    data() {
        return {
            points: [
                "Wouldn't you want to enhance their cognitive growth?",
                "Raising their IQ sounds nice, right?",
                "You want a coach you can trust, right?",
                "Schedule a lesson. You'll be happy with your investment; I promise."
            ]
        }
    }
};
</script>

<template>
    <div
        class="lg:container flex flex-col mx-auto space-y-6 lg:flex-row lg:items-left bg-blend-darken  shadow px-2 py-6 rounded-xl">
        <div class="w-full lg:w-1/2">
            <div class="lg:max-w-lg">
                <h2 class="text-left mb-4">
                    Boost Your Child’s Self-Esteem
                </h2>
                <h1 class="text-left">
                    Unlock Their Full Potential Sharpen Their Mind
                </h1>
            </div>

            <div class="mt-8 space-y-5">
                <p class="items-left mx-2 text-left">

                    Studying chess systematically has been shown to raise students’ IQ and exam scores ,
                    as well as strengthen mathematical, language, and reading skills. Using the Wechsler Intelligence
                    Scale for Children, a Venezuelan study of over 4000 second-grade students found a significant
                    increase in most students’ IQ scores after only 4.5 months of systematically studying chess. This
                    occurred across all socio-economic groups and for both males and females. <a
                        href="https://www.researchgate.net/publication/327337702_Chess_training_improves_cognition_in_children">Source</a>
                </p>
            </div>
        </div>

        <div class=" items-left w-full lg:h-96 lg:w-1/2 ">


            <div class="lg:mt-32 space-y-5">
                <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400 text-lg leading-10 ml-4">
                    <li class="flex items-center text-left mb-2" v-for="item in points" :key="item">
                        <Icon color="black" type="check-outline" />
                        <p>{{ item }}</p>
                    </li>

                </ul>

            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped></style>