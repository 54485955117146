<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10 banner-bg rounded-lg">
		<!-- Banner left contents -->
		<!-- <div class="w-full">
			<img src="@/assets/images/Me-teaching-kids.jpeg" alt="">

		</div> -->
		<div class="mx-auto">
			<img class="w-auto" src="@/assets/images/banner-img.png">
		</div>
		<!-- <div class="w-full md:w-1/3 text-left">
			<h1
				class="font-general-semibold text-4xl md:text-4xl xl:text-6xl text-center sm:text-left text-ternary-dark dark:text-primary-light"
			>
				Lorem ipsum dolor sit amet consectetur adiscing elit
			</h1>
			<div class="flex justify-center sm:block" OCULTO>
				<a
					download="Stoman-Resume.pdf"
					href="/files/Stoman-Resume.pdf"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-pink-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-pink-50 focus:ring-1 focus:ring-pink-900 hover:bg-pink-500 text-gray-500 hover:text-white duration-500"
					aria-label="Download Resume"
				>
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"
					></i>
					<span
						class="text-sm sm:text-lg font-general-medium duration-100"
						>download cv</span
					></a
				>
			</div>
		</div> -->

		<!-- Banner right illustration -->
		<!-- <div class="w-full md:w-2/3 text-right float-right">
			.
			<img
				v-if="theme === 'light'"
				src="@/assets/images/developer.svg"
				alt="Developer"
			/>
			<img
				v-else
				src="@/assets/images/developer-dark.svg"
				alt="Developer"
			/>
		</div> -->
	</section>
</template>

<style scoped>
/* .banner-bg {
	background: #ae935c;
} */
</style>
