import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Ryan - Chess with Ryan",
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/new/About.vue"),
    meta: {
      title: "Ryan - About",
    },
  },
  {
    path: "/projects",
    name: "Projects",
    // route level code-splitting
    // this generates a separate chunk (projects.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/Projects.vue"),
    meta: {
      title: "Ryan - Projetos",
    },
  },
  {
    path: "/projects/single-project",
    name: "Single Project",
    // route level code-splitting
    // this generates a separate chunk (projects.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/SingleProject.vue"),
    meta: {
      title: "Ryan - Single Project",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (projects.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/Contact.vue"),
    meta: {
      title: "Ryan - Contato",
    },
  },
  {
    path: "/book-class",
    name: "Scheduling",
    // route level code-splitting
    // this generates a separate chunk (projects.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      // import(/* webpackChunkName: "projects" */ "../views/new/Scheduling.vue"),
      import("../views/new/PricingV2.vue"),
    meta: {
      title: "Ryan - Book a Class",
    },
  },
  {
    path: "/pricing",
    name: "Pricing",
    // route level code-splitting
    // this generates a separate chunk (projects.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/Pricing.vue"),
    meta: {
      title: "Ryan - Pricing for lesson",
    },
  },
  {
    path: "/lesson-plan",
    name: "LessonPlan",
    // route level code-splitting
    // this generates a separate chunk (projects.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/LessonPlan.vue"),
    meta: {
      title: "Ryan - Lesson Plan",
    },
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    component: () => import("../views/Testimonials.vue"),
    meta: {
      title: "Ryan - Testimonials",
    },
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: () => import("../views/new/Gallery.vue"),
    meta: {
      title: "Ryan - Gallery",
    },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../views/new/Terms.vue"),
    meta: {
      title: "Ryan - Terms",
    },
  },
  {
    path: "/attribution",
    name: "Attribution",
    component: () => import("../views/new/Attribution.vue"),
    meta: {
      title: "Ryan - Pricing",
    },
  },
  // Not in menu
  {
    path: "/home-v2",
    name: "Home2",
    component: () => import("../views/new/Home2.vue"),
    meta: {
      title: "Ryan - Home 2",
    },
  },
  {
    path: "/home-v3",
    name: "Home3",
    component: () => import("../views/new/Home3.vue"),
    meta: {
      title: "Ryan - Home 3",
    },
  },
  {
    path: "/testimonials-wgg-v1",
    name: "Testimonials v2",
    component: () => import("../views/new/Testimonials2.vue"),
    meta: {
      title: "Ryan - Testimonials 2",
    },
  },
  {
    path: "/about-v2",
    name: "About 2",
    component: () => import("../views/new/About.vue"),
    meta: {
      title: "Ryan - About 2",
    },
  },
  {
    path: "/dev",
    name: "Dev",
    component: () => import("../views/new/Dev.vue"),
    meta: {
      title: "Ryan - Dev",
    },
  },
  {
    path: "/dev-more",
    name: "Dev More",
    component: () => import("../views/new/DevMore.vue"),
    meta: {
      title: "Ryan - Dev More",
    },
  },
  {
    path: "/scheduling-v2",
    name: "Scheduling More",
    component: () => import("../views/new/SchedulingV2.vue"),
    meta: {
      title: "Ryan - Scheduling v2",
    },
  },
  {
    path: "/pricing-v2",
    name: "Pricing",
    component: () => import("../views/new/PricingV2.vue"),
    meta: {
      title: "Ryan - Pricing",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

export default router;

/**
 * Below code will display the component/active page title
 * Powered by: Nangialai Stoman
 */

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});
