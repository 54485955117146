<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: 'Chess with Ryan',
			author: 'Shoeab',
			designer: 'Ryan',
		};
	},
};
</script>

<template>
	<div class="xl:container flex justify-center items-center text-center w-full bg-gray-800">
		<div class="font-general-regular text-lg text-white">
			&copy; {{ copyrightDate }} &nbsp;
			<!-- <a href="#"
				class="font-general-medium hover:underline hover:text-pink-600 dark:hover:text-pink-300 duration-500"> -->
			{{ projectName }}
			<!-- </a> -->
			- Designed by <strong>{{ designer }}</strong>, Developed by
			<!-- <a href="#"
				class="font-general-medium text-secondary-dark dark:text-secondary-light hover:underline hover:text-pink-600 dark:hover:text-pink-300 duration-500"> -->
			<strong>{{ author }}</strong>
			<!-- </a> -->
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
